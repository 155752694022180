<template>
  <LayoutVisiteur>
    <LoginCompo />
    <RegisterCompo />
  </LayoutVisiteur>
</template>

<script>
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import LoginCompo from "../../components/login/LoginVisiteur.vue";
import RegisterCompo from "../../components/login/RegisterVisiteur.vue";

export default {
  name: "LoginVisiteur",
  components: {
    LayoutVisiteur,
    LoginCompo,
    RegisterCompo,
  },
};
</script>
