<template>
  <section>
    <div
      class="modal fade"
      id="ModalRegister"
      tabindex="-1"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-body d-flex flex-lg-row flex-column p-0">
            <div class="sub-1 p-4">
              <h4 class="text-primary">Créer un nouveau compte</h4>
              <hr />
              <div>
                <form @submit.prevent="submit" formnovalidate="formnovalidate">
                  <div class="row mb-3">
                    <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                      <label for="inputName" class="form-label">Nom</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputName"
                        v-model="form.last_name_visit"
                        aria-label="Nom"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.last_name_visit.$error,
                        }"
                      />
                      <div
                        v-if="submitted && !$v.form.last_name_visit.required"
                        class="invalid-feedback"
                      >
                        Veuillez insérer votre nom
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <label for="inputPrenom" class="form-label">Prénom</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.first_name_visit"
                        id="inputPrenom"
                        aria-label="Prénom"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.first_name_visit.$error,
                        }"
                      />
                      <div
                        v-if="submitted && !$v.form.first_name_visit.required"
                        class="invalid-feedback"
                      >
                        Veuillez insérer votre prénom
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="inputMail" class="form-label"
                      >Adresse mail</label
                    >
                    <input
                      type="email"
                      class="form-control"
                      v-model="form.email_visit"
                      id="inputMail"
                      aria-describedby="mailInfo"
                      :class="{
                        'is-invalid': submitted && $v.form.email_visit.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.form.email_visit.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.email_visit.required"
                        >Veuillez insérer votre adresse mail</span
                      >
                      <span v-if="!$v.form.email_visit.email"
                        >Veuillez insérer une adresse mail valide</span
                      >
                    </div>
                    <div id="mailInfo" class="form-text">
                      Nous ne divulguerons pas vos informations personnelles
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                      <label for="inputPassword" class="form-label"
                        >Mot de passe</label
                      >
                      <input
                        autocomplete="new-password"
                        type="password"
                        class="form-control"
                        v-model="form.pwd_visit"
                        id="inputPassword"
                        aria-label="Mot de passe"
                        :class="{
                          'is-invalid': submitted && $v.form.pwd_visit.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.form.pwd_visit.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.pwd_visit.required"
                          >Veuillez insérer votre mot de passe</span
                        >
                        <span v-if="form.pwd_visit && !$v.form.pwd_visit.valid"
                          >Le mot de passe doit contenir au moins une majuscule,
                          une minuscule, un nombre et un caractère spécial</span
                        >
                        <span
                          v-if="
                            form.pwd_visit &&
                            $v.form.pwd_visit.valid &&
                            !$v.form.pwd_visit.minLength
                          "
                          >Le mot de passe doit contenir au moins 6
                          caractères</span
                        >
                        <span
                          v-if="form.pwd_visit && !$v.form.pwd_visit.maxLength"
                          >Le mot de passe ne doit pas dépasser les 15
                          caractères</span
                        >
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <label for="inputConfirmPassword" class="form-label"
                        >Confirmer mot de passe</label
                      >
                      <input
                        type="password"
                        class="form-control"
                        v-model="form.confirm_pwd"
                        id="inputConfirmPassword"
                        aria-label="Confirmer mot de passe"
                        :class="{
                          'is-invalid': submitted && $v.form.confirm_pwd.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.form.confirm_pwd.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.confirm_pwd.required"
                          >Veuillez confirmer votre mot de passe</span
                        >
                        <span v-else-if="!$v.form.confirm_pwd.sameAsPassword"
                          >Les mots de passes de correspondent pas</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <input
                      type="checkbox"
                      class="form-check-input me-2"
                      required
                      v-model="consent"
                    />
                    <span
                      >J'ai bien lu et approuvé les Mentions Légales du
                      site</span
                    >
                  </div>
                  <div class="mt-3">
                    <input
                      type="checkbox"
                      class="form-check-input me-2"
                      required
                      v-model="cgv"
                    />
                    <span
                      >J'accepte sans réserve les Conditions Générales de
                      MK-co</span
                    >
                  </div>
                  <div class="d-flex justify-content-end mt-3">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                      ref="close"
                      @click="closeModalRegister"
                    >
                      Annuler
                    </button>
                    <div
                      v-if="loading"
                      class="mx-5 spinner-border text-warning"
                      role="status"
                    ></div>
                    <button
                      v-else
                      type="submit"
                      class="btn btn-primary ms-3"
                      formnovalidate="formnovalidate"
                    >
                      S'enregistrer
                    </button>
                  </div>
                  <hr />
                  <div>
                    <p>Vous avez déja un compte ?</p>
                    <a class="link" data-bs-dismiss="modal">Se connecter</a>
                  </div>
                </form>
              </div>
            </div>
            <div
              class="sub-2 rounded d-flex align-items-center justify-content-center"
            >
              <img
                class="rounded-end"
                src="../../assets/logo/MK-CO-2-W.svg"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { registerVisiteur } from "../../api/visiteur";
import { error } from "../../utils/toast";
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  name: "RegisterVisiteur",
  data() {
    return {
      form: {
        first_name_visit: "",
        last_name_visit: "",
        email_visit: "",
        pwd_visit: "",
        confirm_pwd: "",
      },
      consent: false,
      cgv: false,
      loading: false,
      submitted: false,
    };
  },
  validations: {
    form: {
      first_name_visit: { required },
      last_name_visit: { required },
      email_visit: { required, email },
      pwd_visit: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-_|~]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
        minLength: minLength(6),
        maxLength: maxLength(15),
      },
      confirm_pwd: { required, sameAsPassword: sameAs("pwd_visit") },
    },
  },
  methods: {
    closeModalRegister() {
      this.submitted = false;
    },
    submit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.consent && this.cgv) {
        this.loading = true;
        registerVisiteur(this.form).then((result) => {
          if (result.data.error) {
            this.loading = false;
            error(result.data.error);
          } else {
            this.loading = false;
            this.$refs.close.click();
            //success("Email envoyé avec succès, Vérifier votre courriel");
            this.$swal(
              "Email envoyé avec succès!",
              "Vérifier votre courriel",
              "success"
            );
          }
        });
      } else {
        error("Veuillez accepter les consentements");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-1 {
  flex: 3;

  a {
    cursor: pointer;
  }
}

.sub-2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("../../assets/img/bg-img-4.jpg");
  //   background: url("../../assets/img/bg-img-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  flex: 2;

  img {
    width: 50%;
  }

  @media only screen and (min-width: 960px) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  @media only screen and (max-width: 960px) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
</style>
