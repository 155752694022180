<template>
  <section class="d-flex flex-lg-row flex-column">
    <div class="sub-1 d-flex align-items-center justify-content-center">
      <img src="../../assets/logo/MK-CO-TW.svg" alt="" srcset="" />
    </div>
    <div
      class="sub-2 order-1 p-4 bg-dark text-light d-flex flex-column align-items-start justify-content-start"
    >
      <h4 class="text-primary">Se connecter</h4>
      <hr class="w-100" />
      <div class="container-fluid">
        <div
          class="container-fluid d-flex align-items-end justify-content-center"
        >
          <img
            src="../../assets/illustration/Illus-Admin.svg"
            alt=""
            srcset=""
          />
        </div>
        <form @submit.prevent="submit" formnovalidate="formnovalidate">
          <div class="mb-3">
            <label for="inputMail" class="form-label">Adresse mail*</label>
            <input
              type="email"
              class="form-control"
              v-model="form.email_visit"
              placeholder="nom@mail.com"
              :class="{ 'is-invalid': submitted && $v.form.email_visit.$error }"
            />
            <div
              v-if="submitted && $v.form.email_visit.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.email_visit.required"
                >Veuillez insérer votre adresse mail</span
              >
              <span v-if="!$v.form.email_visit.email"
                >Veuillez insérer une adresse mail valide</span
              >
            </div>
          </div>
          <div class="mb-3">
            <label for="inputPassword" class="form-label">Mot de passe*</label>
            <input
              type="password"
              class="form-control"
              v-model="form.pwd_visit"
              :class="{ 'is-invalid': submitted && $v.form.pwd_visit.$error }"
            />
            <div
              v-if="submitted && $v.form.pwd_visit.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.pwd_visit.required"
                >Veuillez insérer votre mot de passe</span
              >
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <button
              type="submit"
              class="btn btn-primary"
              formnovalidate="formnovalidate"
            >
              Se connecter
            </button>
          </div>
        </form>
        <hr />
        <div>
          <p>Vous ne possedez pas encore un compte ?</p>
          <a
            class="link"
            data-bs-toggle="modal"
            data-bs-target="#ModalRegister"
          >
            Inscrivez-vous ici
          </a>
        </div>
        <hr />
        <div>
          <p>Vous avez oublié votre mot de passe ?</p>
          <router-link to="/mdp-oublie" class="link"
            >Retrouver mon mot de passe</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { loginVisiteur } from "../../api/visiteur";
import { success, error } from "../../utils/toast";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "LoginVisiteur",
  data() {
    return {
      form: {
        email_visit: "",
        pwd_visit: "",
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      email_visit: { required, email },
      pwd_visit: { required },
    },
  },
  methods: {
    async submit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      await loginVisiteur(this.form).then((result) => {
        if (result.data.error) {
          error(result.data.error);
        } else {
          localStorage.setItem("token", result.data[1].token);
          this.$store.dispatch("visiteurStore/setVisiteur", result.data[0]);
          this.$store.dispatch("visiteurStore/setConnected");
          success(`Bienvenue ${result.data[0].first_name_visit} `);
          this.$router.push(this.$route.query.redirect || "/");
        }
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
// section {
//   background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
//     url("../../assets/img/bg-img-9.jpg");
//   background-repeat: no-repeat;
//   background-attachment: fixed;
//   background-size: cover;
//   height: 75vh;
// }

section {
  height: auto;
}

.sub-1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("../../assets/img/bg-img-2.jpg");
  //   background: url("../../assets/img/bg-img-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  flex: 4;

  img {
    width: 50%;
  }
}

.sub-2 {
  flex: 2;

  img {
    width: 35%;
  }

  .link {
    cursor: pointer;
  }
}
</style>
